import React from 'react'

function Navbar() {
    return (
        <div className='flex justify-end py-3 items-center fixed top-0 w-full bg-gray-300/80 backdrop-blur-lg z-50 shadow-white'>
            <h1 className='ml-5 font-bold mr-10'>ben martin.</h1>
        </div>
    )
}

export default Navbar