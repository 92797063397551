import BenMartinPicture from '../assets/ben_martin_profile_logo.jpeg'
import Navbar from "../components/Navbar"
import recentexperiences from "../assets/recentexperiences.png"
import logoLinkedin from "../assets/logoLinkedin.svg"
import logoGithub from "../assets/logoGithub.svg"
import logoMail from "../assets/logoMail.svg"
import leanStartup from "../assets/leanStartup.jpg"
import atomicHabits from "../assets/atomicHabits.png"
import fallInLoveWithTheProblem from "../assets/loveProblem.jpg"
import mastersOfScale from "../assets/mastersOfScale.jpg"
import zeroToOne from "../assets/zero-to-one.png"
import PortfolioPowerhour from "../assets/PortfolioPowerhour.png"

function Home() {
    return (
        <div className='min-h-screen h-full bg-slate-100'>
            <Navbar />
            <div className="p-10 md:pt-40 pt-20">

                <div className="md:grid grid-cols-2 mb-32">
                    <div className='flex justify-center items-center'>
                        <img className="rounded-2xl w-56 shadow-lg hover:shadow-2xl transition-all" src={BenMartinPicture} />
                    </div>
                    <div className=''>
                        <span>
                            <h1 className="text-6xl font-bold inline-block md:mt-0 mt-10">
                                Ben
                            </h1>
                            <h1 className="text-6xl md:inline-block md:ml-4">
                                E. Martin
                            </h1>
                        </span>
                        <h5 className="mb-5">Founder, Coder</h5>
                        <p className="mb-5">Hi there, </p>
                        <p>
                            my name is Ben and I am a 16 year old startup founder and tech enthusiast.
                            Currently I am the founder and CEO of <a href="https://getpowerhour.com" className="font-bold">powerhour</a>, an app that helps you organize and gamify your personal projects.
                            I am interested in startups and how technology can be used to create impact and value in todays world.
                        </p>

                    </div>
                </div>
                <div className="md:grid grid-cols-2 mb-32">
                    <div className='flex justify-center items-center'>
                        <div>
                            <h1 className="text-xl font-bold inline-block md:mt-0 mt-10 mb-5">
                                Recent experiences
                            </h1>
                            <div className=''>
                                <div className='m-0 h-10 flex items-center'>
                                    <div className='rounded-full border-solid border-8 border-blue-700 h-10 w-10 hover:bg-blue-500 transition-all inline-block '></div>
                                    <p className='ml-5 inline-block text-center'>Founder <a href='https://getpowerhour.com' className='font-bold'>powerhour</a></p>
                                </div>
                                <div className='border-solid border-2 border-blue-700 h-5 w-2 ml-4 bg-blue-700 '></div>
                                <div className='m-0 h-10 flex items-center'>
                                    <div className='rounded-full border-solid border-8 border-blue-700 h-10 w-10 hover:bg-blue-500 transition-all inline-block '></div>
                                    <p className='ml-5 inline-block text-center'>Economics Olympiad Winner 2022</p>
                                </div>
                                <div className='border-solid border-2 border-blue-700 h-5 w-2 ml-4 bg-blue-700'></div>
                                <div className='m-0 h-10 flex items-center'>
                                    <div className='rounded-full border-solid border-8 border-blue-700 h-10 w-10 hover:bg-blue-500 transition-all inline-block '></div>
                                    <p className='ml-5 inline-block text-center'>CFO & Co-Founder fairshaked</p>
                                </div>
                                <div className='border-solid border-2 border-blue-500 h-5 w-2 ml-4 bg-blue-500'></div>
                                <a href='https://www.linkedin.com/in/ben-martin-10197620b/' className='underline'>... continue on Linkedin</a>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center justify-center'>
                        <img className="rounded-2xl w-64 transition-all" src={recentexperiences} />
                    </div>
                </div>
                <div className="container mx-auto px-4">
                    <h1 className="text-3xl font-bold text-center mb-8">Some of my projects</h1>
                    <div className="flex flex-wrap justify-center">
                        <div className="m-4 w-96 rounded-lg py-8 px-4 bg-white">
                            <img src={PortfolioPowerhour} className="w-40 m-auto mb-5" alt="Picture of powerhour" />
                            <h3 className='font-bold'>Powerhour</h3>
                            <span>Website: </span><a href='https://getpowerhour.com' className='underline'>getpowerhour.com</a>
                            <p className='mt-2'>
                                Powerhour is an app that turns your personal projects into a game.
                                This helps you make work feel more like a game to avoid procrastination.
                                Powerhour is available on both iOS and Android.
                            </p>
                        </div>
                    </div>

                </div>
                <div className="container mx-auto px-4 mt-6">
                    <h1 className="text-3xl font-bold text-center mb-8">Some of my favorite books</h1>
                    <div className="flex flex-wrap justify-center">
                        <div className="m-4 w-64">
                            <img src={fallInLoveWithTheProblem} className="w-40 m-auto" alt="Book cover" />
                            <p className="text-lg font-bold text-center mt-2">#1 Fall in Love with the Problem, Not the Solution</p>
                            <p className="text-center">Uri Levine</p>
                        </div>
                        <div className="m-4 w-64">
                            <img src={atomicHabits} className="w-40 m-auto" alt="Book cover" />
                            <p className="text-lg font-bold text-center mt-2">#2 Atomic Habits</p>
                            <p className="text-center">James Clear</p>
                        </div>
                        <div className="m-4 w-64">
                            <img src={leanStartup} className="w-40 m-auto" alt="Book cover" />
                            <p className="text-lg font-bold text-center mt-2">#3 The Lean Startup</p>
                            <p className="text-center">Eric Ries</p>
                        </div>
                        <div className="m-4 w-64">
                            <img src={mastersOfScale} className="w-40 m-auto" alt="Book cover" />
                            <p className="text-lg font-bold text-center mt-2">#4 Masters of Scale</p>
                            <p className="text-center">Reid Hoffman</p>
                        </div>
                    </div>
                </div>
                <div className="container px-4 py-8">
                    <h1 className="text-3xl font-bold text-center mb-8">Technical Skills</h1>
                    <div className="md:grid grid-cols-2 gap-4">
                        <div className='flex justify-center'>
                            <div>
                                <div className='mb-5'>
                                    <h2 className="text-2xl font-bold mb-2">General</h2>
                                    <ul className="list-disc pl-6">
                                        <li>Python</li>
                                        <li>C++</li>
                                        <li>JavaScript</li>
                                    </ul>
                                </div>
                                <div className='mb-5'>
                                    <h2 className="text-2xl font-bold mb-2">Web Front-End</h2>
                                    <ul className="list-disc pl-6">
                                        <li>React</li>
                                        <li>Tailwind CSS</li>
                                    </ul>
                                </div>

                                <div className='mb-5'>
                                    <h2 className="text-2xl font-bold mb-2 ">App Development</h2>
                                    <ul className="list-disc pl-6">
                                        <li>Ionic</li>
                                        <li>Capacitor</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center'>
                            <div>
                                <div className='mb-5'>
                                    <h2 className="text-2xl font-bold mb-2">Web Back-End</h2>
                                    <ul className="list-disc pl-6">
                                        <li>Firebase</li>
                                    </ul>
                                </div>
                                <div className='mb-5'>
                                    <h2 className="text-2xl font-bold mb-2 ">UI Design</h2>
                                    <ul className="list-disc pl-6">
                                        <li>Adobe Photoshop</li>
                                        <li>Figma</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




                {/* line break */}
                <hr class="w-48 h-1 mx-auto my-4 bg-gray-700 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
                <div className='text-center'>
                    <h1 className="text-xl font-bold inline-block md:mt-0 mt-10 mb-5 md:mb-2">Contact</h1>
                    <div className='flex w-full justify-center'>
                        <div>
                            <a href='mailto:martin.ben3112@gmail.com'><img src={logoMail} className="inline-block mx-1 w-10"></img></a>
                            <a href="https://www.linkedin.com/in/ben-martin-10197620b/"><img src={logoLinkedin} className="inline-block mx-1 w-12"></img></a>
                            <a href='https://github.com/BenEliasMartin'><img src={logoGithub} className="inline-block mx-1 w-10"></img></a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Home